import { type RefObject, useEffect } from 'react'
import { useLocation } from 'react-router'

export const useScrollToTopOnNavigate = (ref: RefObject<HTMLElement | null>) => {
    const { pathname } = useLocation()

    useEffect(() => {
        if (ref && ref.current) {
            ref.current.scrollTo(0, 0)
        }
    }, [ref, pathname])
}
