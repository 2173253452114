import { type NavigateFunction } from 'react-router'
import type { FeatureCollection, MultiPolygon } from 'geojson'
import { type Hunter } from 'src/redux/hunter/hunter.types'
import { type Task, type TaskType } from 'src/api/fm/tasks/tasks.model'
import type { VehicleCategory } from 'src/api/fm/vehicles/vehicles.model'

export const SET_CURRENT_ORGANIZATION = 'SET_CURRENT_ORGANIZATION'
export const CREATE_ORGANIZATION = 'CREATE_ORGANIZATION'
export const EDIT_ORGANIZATION = 'EDIT_ORGANIZATION'
export const DELETE_ORGANIZATION = 'DELETE_ORGANIZATION'
export const GET_ORGANIZATION = 'GET_ORGANIZATION'
export const GET_ORGANIZATION_ALLOWED_AREAS = 'GET_ORGANIZATION_ALLOWED_AREAS'
export const SET_ORGANIZATION_ALLOWED_AREAS = 'SET_ORGANIZATION_ALLOWED_AREAS'
export const CREATE_ORGANIZATION_ALLOWED_AREA = 'CREATE_ORGANIZATION_ALLOWED_AREA'
export const EDIT_ORGANIZATION_ALLOWED_AREA = 'EDIT_ORGANIZATION_ALLOWED_AREA'
export const DELETE_ORGANIZATION_ALLOWED_AREA = 'DELETE_ORGANIZATION_ALLOWED_AREA'
export const CREATE_HUNTER_IN_ORGANIZATION = 'CREATE_HUNTER_IN_ORGANIZATION'
export const GET_ORGANIZATION_HISTORY_TASKS = 'GET_ORGANIZATION_HISTORY_TASKS'
export const SET_ORGANIZATION_HISTORY_TASKS = 'SET_ORGANIZATION_HISTORY_TASKS'
export const SET_IS_FETCHING_ORGANIZATION_HISTORY_TASKS = 'SET_IS_FETCHING_ORGANIZATION_HISTORY_TASKS'
export const SET_ORGANIZATION_HISTORY_FILTER = 'SET_ORGANIZATION_HISTORY_FILTER'
export const INCREMENT_ORGANIZATION_HISTORY_PAGE_NUM = 'INCREMENT_ORGANIZATION_HISTORY_PAGE_NUM'
export const SET_END_OF_PAGINATION = 'SET_END_OF_PAGINATION'
export const GET_NEXT_HISTORY_PAGE = 'GET_NEXT_HISTORY_PAGE'
export const EDIT_ORGANIZATION_SUCCEEDED = 'EDIT_ORGANIZATION_SUCCEEDED'
export const CREATE_ORGANIZATION_SUCCEEDED = 'CREATE_ORGANIZATION_SUCCEEDED'
export const DELETE_ORGANIZATION_SUCCEEDED = 'DELETE_ORGANIZATION_SUCCEEDED'

export interface Organization {
    id: string
    type: string | null // TODO: enum?
    name: string
    market: string | null // TODO: enum?
    email: string
    status: string | undefined // TODO: enum?
    iban?: string
    bic?: string
    currency?: string
    hunters?: Hunter[]
    bankAccountNumber?: string
    bankName?: string
    companyName?: string
    companyAddress?: string
    companyNumber?: string
    warehouseAddress?: string
    warehouseZipcode?: string
    mainVoiContact: string
    applied?: string
    ending?: string
    vehicleType?: string
    vehicleLicensePlate?: string
    createdAt: string
    updatedAt: string
    allowedTaskTypes: TaskType[]
    workAreasEnabled: boolean
    routingEnabled: boolean
}

export interface OrganizationAllowedArea {
    id: string
    organizationId: string
    zoneId: string
    name: string
    taskTypes: TaskType[]
    vehicleCategories: VehicleCategory[]
    area: FeatureCollection<MultiPolygon>
    updatedAt: string
}

export interface OrganizationHistoryFilters {
    email: string
}

export interface OrganizationState {
    organization: Organization | null
    allowedAreas: OrganizationAllowedArea[]
    history: Task[]
    isFetchingHistory: boolean
    filters: OrganizationHistoryFilters | null
    pageNum: number
    endOfPagination: boolean
}

export interface EditOrganization {
    type: typeof EDIT_ORGANIZATION
    payload: Organization
}

export interface CreateHunterInOrganization {
    type: typeof CREATE_HUNTER_IN_ORGANIZATION
    payload: any
}

export interface DeleteOrganization {
    type: typeof DELETE_ORGANIZATION
    payload: { orgId: string; navigateFn: NavigateFunction }
}

export interface GetOrganization {
    type: typeof GET_ORGANIZATION
    payload: string
}

export interface SetCurrentOrganization {
    type: typeof SET_CURRENT_ORGANIZATION
    payload: Organization | null
}

export interface GetOrganizationHistoryTasks {
    type: typeof GET_ORGANIZATION_HISTORY_TASKS
}

export interface SetOrganizationHistoryTasks {
    type: typeof SET_ORGANIZATION_HISTORY_TASKS
    payload: Task[]
}

export interface SetIsFetchingOrganizationHistoryTasks {
    type: typeof SET_IS_FETCHING_ORGANIZATION_HISTORY_TASKS
    payload: boolean
}

export interface CreateOrganization {
    type: typeof CREATE_ORGANIZATION
    payload: { organization: Organization; pushToRoute: (id: string | undefined) => void }
}

export interface GetOrganizationAllowedAreas {
    type: typeof GET_ORGANIZATION_ALLOWED_AREAS
    payload: string
}

export interface SetOrganizationAllowedAreas {
    type: typeof SET_ORGANIZATION_ALLOWED_AREAS
    payload: OrganizationAllowedArea[]
}

export interface CreateOrganizationAllowedArea {
    type: typeof CREATE_ORGANIZATION_ALLOWED_AREA
    payload: OrganizationAllowedArea
}

export interface EditOrganizationAllowedArea {
    type: typeof EDIT_ORGANIZATION_ALLOWED_AREA
    payload: OrganizationAllowedArea
}

export interface DeleteOrganizationAllowedArea {
    type: typeof DELETE_ORGANIZATION_ALLOWED_AREA
    payload: OrganizationAllowedArea
}

export interface SetOrganizationHistoryFilter {
    type: typeof SET_ORGANIZATION_HISTORY_FILTER
    payload: OrganizationHistoryFilters
}

export interface IncrementOrganizationHistoryPageNum {
    type: typeof INCREMENT_ORGANIZATION_HISTORY_PAGE_NUM
}

export interface SetEndOfPagination {
    type: typeof SET_END_OF_PAGINATION
}

export interface GetNextHistoryPage {
    type: typeof GET_NEXT_HISTORY_PAGE
}

export interface EditOrganizationSucceeded {
    type: typeof EDIT_ORGANIZATION_SUCCEEDED
    payload: Organization
}

export interface CreateOrganizationSucceeded {
    type: typeof CREATE_ORGANIZATION_SUCCEEDED
    payload: Organization
}

export interface DeleteOrganizationSucceeded {
    type: typeof DELETE_ORGANIZATION_SUCCEEDED
    payload: string
}

export type OrganizationActions =
    | EditOrganization
    | CreateHunterInOrganization
    | DeleteOrganization
    | GetOrganization
    | GetOrganizationAllowedAreas
    | SetOrganizationAllowedAreas
    | CreateOrganizationAllowedArea
    | EditOrganizationAllowedArea
    | DeleteOrganizationAllowedArea
    | SetCurrentOrganization
    | GetOrganizationHistoryTasks
    | SetOrganizationHistoryTasks
    | SetIsFetchingOrganizationHistoryTasks
    | CreateOrganization
    | SetOrganizationHistoryFilter
    | IncrementOrganizationHistoryPageNum
    | SetEndOfPagination
    | GetNextHistoryPage
    | EditOrganizationSucceeded
    | CreateOrganizationSucceeded
    | DeleteOrganizationSucceeded
