import { CircularProgress } from '@mui/material'
import styled from '@emotion/styled'
import { black, coral, hoverBlack, hoverCoral, hoverTransparent, white } from 'src/ui-kit/colors/colors'
import { fontSecondary } from 'src/ui-kit/fonts/fontFamily'
import { BUTTON_COLORS, BUTTON_TYPES } from 'src/ui-kit/button/constants'

const getButtonComponent = (type: (typeof BUTTON_TYPES)[keyof typeof BUTTON_TYPES]) => {
    switch (type) {
        case BUTTON_TYPES.PRIMARY:
            return PrimaryButton
        case BUTTON_TYPES.OUTLINED:
            return OutlinedButton
        case BUTTON_TYPES.TEXT_REGULAR:
            return TextRegularButton
        case BUTTON_TYPES.TEXT_SMALL:
            return TextSmallButton
    }
}

const getColors = (btnColor: (typeof BUTTON_COLORS)[keyof typeof BUTTON_COLORS]) => {
    switch (btnColor) {
        case BUTTON_COLORS.CORAL:
            return { realColor: coral, hoverColor: hoverCoral, textColor: white }
        case BUTTON_COLORS.BLACK:
            return { realColor: black, hoverColor: hoverBlack, textColor: white }
        case BUTTON_COLORS.WHITE:
            return { realColor: white, hoverColor: white, textColor: black }
    }
}

type Props = {
    isDisabled?: boolean
    isLoading?: boolean
    isStretched?: boolean
    dataTestId?: string
    className?: string
    actionType?: 'button' | 'submit' | 'reset'
    type?: (typeof BUTTON_TYPES)[keyof typeof BUTTON_TYPES]
    color?: (typeof BUTTON_COLORS)[keyof typeof BUTTON_COLORS]
    onClick?: () => void
    children?: any
}

export const Button = ({
    isDisabled,
    isLoading,
    isStretched,
    dataTestId,
    className,
    actionType = 'button',
    type = BUTTON_TYPES.PRIMARY,
    color = BUTTON_COLORS.CORAL,
    onClick,
    children,
}: Props) => {
    const BtnComponent = getButtonComponent(type)
    const { realColor, hoverColor, textColor } = getColors(color)

    return (
        <BtnComponent // Additional `disabled` prop for the HTML node
            disabled={isDisabled || isLoading}
            $isDisabled={!!isDisabled}
            $isLoading={!!isLoading}
            $isStretched={!!isStretched}
            data-testid={dataTestId}
            className={className}
            type={actionType}
            $color={realColor}
            $hoverColor={hoverColor}
            $textColor={textColor}
            onClick={onClick}
        >
            {isLoading && (
                <LoadingState>
                    <CircularProgress color='inherit' size={type === BUTTON_TYPES.TEXT_SMALL ? 13 : 18} />
                </LoadingState>
            )}

            <Content $isLoading={!!isLoading}>{children}</Content>
        </BtnComponent>
    )
}

const LoadingState = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
`

const Content = styled.div<{ $isLoading: boolean }>`
    display: inline-flex;
    align-items: center;
    justify-content: center;

    ${({ $isLoading }) =>
        $isLoading &&
        `
        visibility: hidden;
    `}
`

const BaseButton = styled.button<{
    $isDisabled: boolean
    $isStretched: boolean
    $isLoading: boolean
    $color: string
    $hoverColor: string
    $textColor: string
}>`
    border-radius: 30px;
    outline: none;
    text-transform: uppercase;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    opacity: ${({ $isDisabled }) => ($isDisabled ? '0.5' : '1')};
    padding: 8px 32px;
    ${fontSecondary};
    font-size: 14px;
    font-weight: bold;
    border: none;
    width: ${({ $isStretched }) => ($isStretched ? '100%' : undefined)};
    min-width: 124px;
    user-select: none;
    position: relative;

    &:active {
        opacity: ${({ $isLoading }) => ($isLoading ? '1' : '0.8')};
    }

    &:hover {
        cursor: ${({ $isDisabled, $isLoading }) => {
            if ($isDisabled) {
                return 'not-allowed'
            } else if ($isLoading) {
                return 'default'
            }

            return 'pointer'
        }};
    }
`

const PrimaryButton = styled(BaseButton)`
    color: ${({ $textColor }) => $textColor};
    background-color: ${({ $color }) => $color};

    &:hover:not(:disabled) {
        background-color: ${({ $hoverColor }) => $hoverColor};
    }
`

const OutlinedButton = styled(BaseButton)`
    border: 2px solid ${({ $color }) => $color};
    padding: 6px 30px;
    color: ${({ $color }) => $color};
    background-color: transparent;

    &:active {
        opacity: ${({ $isLoading }) => ($isLoading ? '1' : '0.6')};
    }

    &:hover:not(:disabled) {
        background-color: ${hoverTransparent};
    }
`

const TextRegularButton = styled(BaseButton)`
    color: ${({ $color }) => $color};
    background-color: transparent;
    padding: 0;
    min-width: initial;

    &:active {
        opacity: ${({ $isLoading }) => ($isLoading ? '1' : '0.6')};
    }
`

const TextSmallButton = styled(TextRegularButton)`
    font-size: 10px;
    line-height: 13px;
`
