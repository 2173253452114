import { type ReactElement, type ReactNode, isValidElement, useState } from 'react'
import { ClickAwayListener, MenuItem, type TooltipProps, Tooltip } from '@mui/material'
import { styled as styledMui } from '@mui/material/styles'
import styled from '@emotion/styled'
import DropDownIcon from 'src/assets/images/ic_dropdown.svg'
import * as colors from 'src/ui-kit/colors/colors'
import { VoiIcon } from 'src/ui-kit/voiIcon/VoiIcon'

interface Props extends Omit<TooltipProps, 'title' | 'children'> {
    children: ReactNode[]
    anchor?: ReactElement<any, any>
    closeOnClickMenuItem?: boolean
    onOpen?: () => void
    onClose?: () => void
    childrenTestId?: string
}

export const TooltipMenu = ({
    children,
    anchor = <VoiIcon src={DropDownIcon} />,
    closeOnClickMenuItem = true,
    onOpen = () => {},
    onClose = () => {},
    childrenTestId,
    ...rest
}: Props) => {
    const [open, setOpen] = useState(false)

    const toggleOpen = () => {
        if (open) {
            setOpen(false)
            onClose()
        } else {
            setOpen(true)
            onOpen()
        }
    }

    const onClickMenuItem = () => {
        if (closeOnClickMenuItem) {
            setOpen(false)
            onClose()
        }
    }

    return (
        <StyledTooltip
            arrow
            open={open}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title={
                <ClickAwayListener
                    onClickAway={() => {
                        setOpen(false)
                        onClose()
                    }}
                >
                    <MenuContainer data-testid={childrenTestId}>
                        {children.map((item, index) => (
                            <StyledMenuItem key={index} dense onClick={() => onClickMenuItem()}>
                                {isValidElement(item) ? (
                                    <ElementWrapper>{item}</ElementWrapper>
                                ) : (
                                    <NoneElementWrapper>{item}</NoneElementWrapper>
                                )}
                            </StyledMenuItem>
                        ))}
                    </MenuContainer>
                </ClickAwayListener>
            }
            {...rest}
        >
            <AnchorWrapper onClick={toggleOpen}>{anchor}</AnchorWrapper>
        </StyledTooltip>
    )
}

const StyledTooltip = styledMui(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    '& .MuiTooltip-tooltip': {
        pointerEvents: 'initial',
        color: colors.black,
        backgroundColor: colors.white,
        boxShadow: theme.shadows[3],
        padding: 0,

        '& .MuiTooltip-arrow::before': {
            backgroundColor: colors.white,
        },
    },
}))

const StyledMenuItem = styledMui(MenuItem)({
    '&.MuiMenuItem-root': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '4px',
        padding: 0,
        fontSize: 14,
        fontFamily: '"Montserrat", monospace',

        '&:hover': {
            backgroundColor: colors.lightGrey,
            color: colors.coral,
        },

        '&:not(:last-child)': {
            borderBottom: `1px solid ${colors.dividerGrey}`,
        },
    },
})

const MenuContainer = styled.div`
    max-height: 400px;
    overflow: auto;
`

const AnchorWrapper = styled.span`
    display: flex;
    cursor: pointer;
`

const ElementWrapper = styled.div`
    width: 100%;

    & > * {
        padding: 15px 30px;
    }
`

const NoneElementWrapper = styled.div`
    padding: 15px 30px;
`
